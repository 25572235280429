import axios from "@/utils/request";

// 查询关注列表
export function queryCareList(data) {
    return axios.post(`/care/list`, data);
}

// 关注、取消关注
export function careUser(data) {
    return axios.post(`/care/user`, data);
}