<template>
    <div class="focusOnPage">
        <div class="navbar">
            <div class="navbar_btn" @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow"></svg-icon>
            </div>
            <div class="navbar_title">关注</div>
        </div>
        <div class="body">
            <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
                :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="true">
                <div class="userItem" v-for="(item, index) in focusOnList" :key="index">
                    <!-- <img :src="item.url" class="avatar"> -->
                    <ImgDecypt :src="item.avatar" round class="avatar" @click.native="toUserInfo(item)" />
                    <div class="infoBox">
                        <div class="name">{{item.nickName}}</div>
                        <div class="introduction">{{item.introduce || '这个人很懒，什么都没留下。'}}</div>
                    </div>
                    <div class="btn" @click="careOrcancle(item)">{{item.followSta ? '已关注' : '关注'}}</div>
                </div>
            </Pullrefresh>
        </div>
    </div>
</template>

<script>
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    import {
        careUser,
        queryCareList,
    } from '@/api/mine/focusOn'
    export default {
        components: {
            Pullrefresh,
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                isNoData: false,
                firstLoading: true, //是否第一次加载
                focusOnList: [],
                pageNum: 1,
                pageSize: 10,
            }
        },
        created() {
            this.getData();
        },
        methods: {
            //上滑加载
            onLoad() {
                this.pageNum++;
                this.getData();
            },
            //刷新
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.focusOnList = [];
                this.getData();
            },
            //获取数据
            async getData() {
                this.loading = true;
                let req = {
                    pageSize: this.pageSize,
                    pageNum: this.pageNum,
                }
                let ret = await this.$Api(queryCareList, req);

                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;

                if (ret && ret.code == 200) {
                    let list = ret.data.list;
                    list.forEach(i => {
                        i.followSta = true;
                    });
                    this.focusOnList = this.focusOnList.concat(list);
                    // if (this.focusOnList.length > 0) {
                    //     this.author = this.mediaInfos[0].author;
                    // }

                    if (list.length == 0 && this.pageNum == 1) {
                        this.isNoData = true;
                        return;
                    }
                    if (list.length < this.pageSize) {
                        this.finished = true;
                    }
                }
            },
            // 关注、取消关注
            async careOrcancle(item) {
                let req = {
                    isCare: !item.followSta,
                    userId: item.userId,
                }
                this.$toast.loading({
                    duration: 0,
                });
                let ret = await this.$Api(careUser, req);
                if (ret && ret.code == 200) {
                    item.followSta = !item.followSta;
                } else {
                    ret.tip && this.$toast(ret.tip);
                }
                this.$toast.clear();
            },
            toUserInfo(item) {
                this.$router.push({path: '/bloggerDetails', query: {id: item.userId}})
            }
        }
    }
</script>

<style lang='scss' scoped>
    .focusOnPage {
        background: $whiteThree;
        height: 100vh;
        // padding: 44px 0;

        .navbar {
            // position: fixed;
            // top: 0;
            background: $vermillion;
            // width: 100%;
            color: $white1;
            height: 44px;
            font-size: 20px;
            padding: 0 16px;

            display: flex;
            align-items: center;

            .navbar_btn {
                margin-right: 28px;
            }

            .navbar_title {
                font-weight: 600;
            }
        }

        .body {
            height: calc(100vh - 44px);

            .userItem {
                width: 100%;
                height: 70px;
                padding: 13px 16px 13px 23px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: $white1;

                .avatar {
                    width: 44px;
                    height: 44px;
                    border-radius: 44px;
                }

                .infoBox {
                    flex: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin: 0 24px;
                    width: 180px;
                    height: 86%;

                    .name {
                        font-size: 13px;
                        font-weight: 600;
                    }

                    .introduction {
                        font-size: 10px;
                        color: $greyishBrown;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .btn {
                    width: 64px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    font-size: 12px;
                    color: $white1;
                    background: $vermillion;
                    border-radius: 16px;
                }
            }
        }
    }
</style>